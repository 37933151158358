import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/PokemonRNGGuides/PokemonRNGGuides/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`All contributions are welcome!`}</h2>
    <p>{`Examples include:`}</p>
    <ul>
      <li parentName="ul">{`Adding a new guide`}</li>
      <li parentName="ul">{`Updating an old guide`}</li>
      <li parentName="ul">{`Fixing typos or dead links`}</li>
      <li parentName="ul">{`Feature requests`}</li>
      <li parentName="ul">{`Anything else you'd like to contribute!`}</li>
    </ul>
    <h3>{`Needing ideas?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/zaksabeast/PokemonRNGGuides/projects/3"
      }}>{`Click here for a list of missing guides!`}</a></p>
    <h2>{`How to contribute?`}</h2>
    <h3>{`Discord`}</h3>
    <p>{`Any contributions can be posted to `}<a parentName="p" {...{
        "href": "https://discord.gg/wR9fygN"
      }}>{`the guide contributions channel on our Discord`}</a>{`, and someone can add the contribution to the site on your behalf.`}</p>
    <h3>{`GitHub`}</h3>
    <p>{`Open a pull request on `}<a parentName="p" {...{
        "href": "https://github.com/zaksabeast/PokemonRNGGuides"
      }}>{`the GitHub repository`}</a>{`!`}</p>
    <p>{`This will give you credits in the GitHub repository. If you're new to GitHub, `}<a parentName="p" {...{
        "href": "https://www.freecodecamp.org/news/how-to-make-your-first-pull-request-on-github-3/"
      }}>{`reading this guide`}</a>{` can help get you started. You can also ask for help on `}<a parentName="p" {...{
        "href": "https://discord.gg/wR9fygN"
      }}>{`our Discord`}</a>{`.`}</p>
    <p>{`Alternatively, open an `}<a parentName="p" {...{
        "href": "https://github.com/zaksabeast/PokemonRNGGuides/issues"
      }}>{`issue on GitHub`}</a>{` if there's a feature you'd like to see added to the site.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      